@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@font-face {
  font-family: 'Euclid Regular';
  src: url(./assets/fonts/EuclidCircularB-Medium-WebS.woff) format("woff");
}
@font-face {
  font-family: 'Euclid Medium';
  src: url(./assets/fonts/EuclidCircularB-Medium-WebS.woff) format("woff");
}
@font-face {
  font-family: 'Euclid Semibold';
  src: url(./assets/fonts/EuclidCircularB-Semibold-WebS.woff) format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom scrollbar styles */
.tab-scrollbar::-webkit-scrollbar {
  display: none; 
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}