.article-body p {
    margin-top: 20px;
    width: 100%;
}

.article-body li {
    list-style: inside;
}

.article-body ul {
    margin-top: 10px;
}

.article-body {
    display: flex;
    flex-direction: column;
}

.article-body > em {
    font-size: 12px;
    font-style: normal;
    margin-top: 6px;
    margin-bottom: -10px;
}

.article-body a,
.article-body a u {
    text-decoration: underline;
    text-decoration-color: #46ce84;
}
.article-body a:hover,
.article-body a u:hover {
    color: #46ce84;
}

.article-body .imgParent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.article-body .imgParent img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    align-self: center;
}
